.paginator-demo {
  margin-top: 1rem;
}

.paginator-demo .p-button.p-button-icon-only {
  border-radius: 0;
}

.paginator-demo .image-gallery {
  text-align: center;
  padding: 1rem;
}
